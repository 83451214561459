// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const EventCalendar = () => {
//   const [calendarData, setCalendarData] = useState([]);

//   useEffect(() => {
//     const fetchCalendarData = async () => {
//       try {
//         const accessToken = 'secret_tNHzciWD1FwsYTQOCxV4EF8Z1T264J5OHX1TwLbS9mI';
//         const response = await axios.get('https://api.notion.com/v1/calendar/events', {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//             'Notion-Version': '2021-05-13',
//           },
//         });

//         // Extract the necessary calendar data from the response
//         const data = response.data;

//         setCalendarData(data);
//       } catch (error) {
//         console.error('Error fetching calendar data:', error);
//       }
//     };

//     fetchCalendarData();
//   }, []);

//   return (
//     <div>
        
//       {/* Render the calendar data */}
//       {/* You can use map() or other iteration methods to render events, dates, etc. */}
//       {calendarData.map((event) => (
//         <div key={event.id}>{event.title}</div>
//       ))}
//     </div>
//   );
// };

// export default EventCalendar;
import React from 'react';
import Iframe from 'react-iframe';
import Navbar from '../Components/Navbar';

const YourComponent = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'left',marginTop: '-20px',width:'100%', backgroundColor: '#FFF8F1' }}>
     
        <Navbar ></Navbar>
   
        <div style={{ width: '100%', height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div style={{ width: '80%', height: '100%' }}>
      <Iframe
        url="https://v2-embednotion.com/c79fca983210482b86ee61e38801adca?v=45d6c1effd56400898464e5c468ed666&pvs=4"
        width="100%"
        height="100%"
        frameBorder="0"
        allowFullScreen
        style={{ border: '2px solid #ccc', borderRadius: '10px' }}
      />
    </div>
  </div>
    </div>
  );
};

export default YourComponent;
