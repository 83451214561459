import React, { useState, useRef, useEffect } from 'react';
import './AboutUs.css';
import useScrollSnap from 'react-use-scroll-snap';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faEnvelope ,faCircleChevronUp} from '@fortawesome/free-solid-svg-icons';
import Navbar from '../Components/Navbar';
import team from '../Assets/Jon.jpg';
import giang from '../Assets/Giang.png'

import JonH from "../Assets/JonH.png"
import SeanW from "../Assets/SeanW.png"
import CocoI from "../Assets/CocoI.png"
import GiangN from "../Assets/giangN.png"
import JohnL from "../Assets/JohnL.png"
import STB from "../Assets/STB.png"
import Roger from "../Assets/Roger.png"

const AboutUs = () => {

  const scrollRef1 = useRef(null);
  const scrollRef2 = useRef(null);
  const scrollRef3 = useRef(null);
  const scrollRef4 = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const scrollRef = useRef(null);
  useScrollSnap({ ref: scrollRef, duration: 100, delay: 50 });
  const [scrollTop, setScrollTop] = useState(0);
 
  useEffect(() => {
    // Scroll to ref1 when the page loads
    handleScrollToRef1();
  }, []);
  const handleScrollToRef1 = () => {
    if (scrollRef1.current) {
      scrollRef1.current.scrollIntoView({ behavior: 'smooth' });
    }
  };


  
  // Simulating a delay to showcase the loading state
  useEffect(() => {
    const delay = 1000; // Specify the delay in milliseconds (1 second in this example)
  
    const timer = setTimeout(() => {
      setIsLoading(false);
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, delay);
  
    return () => clearTimeout(timer);
  }, []);
 useEffect(() => {
  const handleScrollEvent = () => {
    const scrollPosition = scrollRef.current.scrollTop;
    // console.log('Scroll position:', scrollPosition);
    console.log(scrollPosition);
    console.log('ScrollREF0 position:',  scrollRef.current.scrollHeight);
    console.log('ScrollREF1 position:',  scrollRef1.current.offsetTop);
    console.log('ScrollREF2 position:',  scrollRef2.current.offsetTop);
    console.log('ScrollREF3 position:',  scrollRef3.current.offsetTop);
    console.log('ScrollREF4 position:',  scrollRef4.current.offsetTopt);
    if (scrollPosition <= scrollRef1.current.scrollHeight) {
      setCurrentPage(1);
    } else if (scrollPosition <=  scrollRef1.current.offsetTop) {
      setCurrentPage(1);
    } else if (scrollPosition <=  scrollRef2.current.offsetTop + 100) {
      setCurrentPage(2);
    } else if (scrollPosition <=  scrollRef3.current.offsetTop + 100) {
      setCurrentPage(3);
    } else {
      setCurrentPage(4);
    }
  };

  if (scrollRef.current) {
    scrollRef.current.addEventListener('scroll', handleScrollEvent);
  }

  return () => {
    if (scrollRef.current) {
      scrollRef.current.removeEventListener('scroll', handleScrollEvent);
    }
  };
}, []);


  


//   const handleScroll = () => {
//     const sectionRefs = [scrollRef1, scrollRef2, scrollRef3, scrollRef4];
//     const sectionHeight = scrollRef1.current.scrollHeight / sectionRefs.length;
//     const scrollPosition = scrollRef1.current.scrollTop;
//     const page = Math.ceil((scrollPosition + 1) / sectionHeight);
//     setCurrentPage(page);
//   };
  
//   useEffect(() => {
//   const handleScrollEvent = () => {
//     handleScroll(scrollRef1);
//   };

//   if (scrollRef1.current) {
//     scrollRef1.current.addEventListener('scroll', handleScrollEvent);
//   }

//   return () => {
//     if (scrollRef1.current) {
//       scrollRef1.current.removeEventListener('scroll', handleScrollEvent);
//     }
//   };
// }, []);



  return (
    
    <div
      style={{
        height: '100vh',
   
        backgroundColor: '#FFF8F1',
        scrollSnapType: 'y mandatory', // Enable vertical scroll snapping
        overflowY: 'scroll', // Enable vertical scrolling
        scrollBehavior: 'smooth'
      }}
      ref={scrollRef}
    >

      <Navbar  ></Navbar>

      <div
  style={{
    position: 'fixed',
    bottom: '0',
    right: '10vh',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-end', // Align the content to the right
    justifyContent: 'flex-end',
    zIndex: 1, // Ensure the sticky element stays above the sections
  }}
>
<h1
  style={{
    fontSize: '3.5vw',
    fontWeight: '20',
    display: 'flex',
    color:'black',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: '',
    // color: '#BCC1CA',
    fontFamily: 'Roboto Mono, monospace',
    marginRight: '1rem', // Add some right margin for spacing
  }}
>
<FontAwesomeIcon
  style={{ color: 'black', marginBottom: '3vh', transition: '' }}
  icon={faCircleChevronUp}
  onClick={handleScrollToRef1}
  className="icon"
></FontAwesomeIcon>
  0{currentPage}/04
</h1>
</div>


      {/* About us - page 01*/}
      <section
        ref={scrollRef1}
        style={{
          scrollMarginTop: '0',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            filter: isLoading ? 'blur(5px)' : 'none',
            transition: 'filter 0.5s ease',
            scrollSnapAlign: 'start',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '80%', height: '100vh', justifyContent:'center' }}>
            <h1
              style={{
                backgroundColor: '',
                fontSize: '10vh',
                color: 'black',
                textAlign: 'left',
                marginTop: '200px',
              }}
            >
              about us
              <span className="dot-container2" style={{ marginBottom: '2rem' }}></span>
            </h1>
            <p style={{ textAlign: 'left', color: 'black', width: '80%' }}>
              We are a boutique digital marketing company that specializes in delivering creative and innovative
              digital marketing solutions for businesses across various industries. We aim to provide our clients with
              tailored and personalized marketing strategies that drive growth and increase their online presence.
            </p>

            <p style={{ textAlign: 'left', color: 'black', width: '80%' }}>
              At Envision Media, we understand how important it is to stay ahead of the game in the highly competitive
              digital landscape. Our team of experts is constantly exploring new and emerging technologies to ensure
              that we deliver up-to-date and effective marketing campaigns that cater to our clients' unique needs.
            </p>
          </div>
        </div>
      </section>

      <section
        ref={scrollRef2}
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          marginLeft:'10vh'
    
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            filter: isLoading ? 'blur(5px)' : 'none',
            transition: 'filter 0.5s ease',
            scrollSnapAlign: 'start',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '80%', height: '100vh', backgroundColor: '' , justifyContent:'center'}}>
            <h1
              style={{
                backgroundColor: '',
                fontSize: '7vh',
                marginTop: '200px',
                color: 'black',
                textAlign: 'left',
                width: '20%',
              }}
            >
              a diverse portfolio
              <span className="dot-container2" style={{ marginBottom: '-2rem', marginLeft: '2rem' }}></span>
            </h1>
            <p style={{ textAlign: 'left', color: 'black', width: '80%', fontSize: '5vh', lineHeight: '10vh' }}>
              40+ Projects
              <br />
              60+ Clients
              <p style={{ textAlign: 'left', color: 'black', width: '80%', fontSize: '5vh', lineHeight: '5vh', marginTop:'2vh'}}>Over 5 million acquired users</p>
            </p>

      
          </div>
        </div>
      </section>

      <section
        ref={scrollRef3}
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          marginLeft:'20vh'
      
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            filter: isLoading ? 'blur(5px)' : 'none',
            transition: 'filter 0.5s ease',
            scrollSnapAlign: 'start',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: '', marginLeft:'', width:'100vh',alignItems: '', justifyContent:'center'}}>
            <div style={{ display: 'flex', marginTop: '200px' }}>
              <div style={{ marginRight: '2rem' }}>
                <img
                  style={{
                    borderRadius: '50%',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    border: '0.7vh solid white',
                    borderColor: 'white',
                    height: '17vh',
                    width: '17vh',
                  }}
                  src={JohnL}
                  alt="Team"
                />
                <p style={{ color: 'black' }}>John Lagman</p>
                <hr style={{width:'20%'}}></hr>
                <p style={{ color: 'gray'}}>Bloomberg</p>
                <p style={{ color: 'gray', marginTop:'-2vh', fontSize:'0.9vw'}}>APAC Crypto Lead</p>
  
              </div>
              <div style={{ marginRight: '2rem' }}>
                <img
                  style={{
                    borderRadius: '50%',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    border: '0.7vh solid white',
                    borderColor: 'white',
                    height: '17vh',
                    width: '17vh',
                  }}
                  src={SeanW}
                  alt="Team"
                />
                <p style={{ color: 'black' }}>Sean Wu</p>
                <hr style={{width:'20%'}}></hr>
                <p style={{ color: 'gray'}}>Aspen Digital</p>
                
                <p style={{ color: 'gray', marginTop:'-2vh', fontSize:'0.9vw'}}>Head of Investments</p>
              </div>
              {/* <div style={{ marginRight: '2rem' }}>
                <img
                  style={{
                    borderRadius: '50%',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    border: '0.7vh solid white',
                    borderColor: 'white',
                    height: '17vh',
                    width: '17vh',
                  }}
                  src={STB}
                  alt="Team"
                />
                <p style={{ color: 'black' }}>Sng Tong Beng</p>
                <hr style={{width:'20%'}}></hr>
                <p style={{ color: 'gray'}}>Leo Burnett</p>
                
                <p style={{ color: 'gray', marginTop:'-2vh', fontSize:'0.9vw'}}>Creative Director</p>
              </div> */}
              {/* <div style={{ marginRight: '2rem' }}>
                <img
                  style={{
                    borderRadius: '50%',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    border: '0.7vh solid white',
                    borderColor: 'white',
                    height: '17vh',
                    width: '17vh',
                  }}
                  src={Roger}
                  alt="Team"
                />
                <p style={{ color: 'black' }}>Roger Ver</p>
                <hr style={{width:'20%'}}></hr>
                <p style={{ color: 'gray'}}>Bitcoin.com</p>
                
                <p style={{ color: 'gray', marginTop:'-2vh', fontSize:'0.9vw'}}>CEO</p>
              </div> */}
            </div>
            <h1
              style={{
                backgroundColor: '',
                fontSize: '10vh',
                color: 'black',
                textAlign: 'left',
                width: '40%',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginTop:'2vh',
              }}
            >
              our advisors
              <span className="dot-container2" style={{ marginBottom: '2rem', marginLeft: '2rem' }}></span>
            </h1>
          </div>
        </div>
      </section>

      <section
        ref={scrollRef4}
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          marginLeft:'0vh'
      
         
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            filter: isLoading ? 'blur(5px)' : 'none',
            transition: 'filter 0.5s ease',
            scrollSnapAlign: 'center',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: '', height: '100vh', backgroundColor: '', marginLeft:'30%',justifyContent: 'center'}}>
            <div style={{ display: 'flex', marginTop: '200px' }}>
              <div style={{ marginRight: '2rem' }}>
                <img
                  style={{
                    borderRadius: '50%',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    border: '0.7vh solid white',
                    borderColor: 'white',
                    height: '17vh',
                    width: '17vh',
                  }}
                  src={JonH}
                  alt="Team"
                />
                <p style={{ color: 'black' }}>Jonathan Ho</p>
                <hr style={{width:'20%'}}></hr>
                <p style={{ color: 'gray', lineHeight:'3vh'}}>Managing <br/> Director</p>
                {/* <hr style={{width:'20%'}}></hr> */}
                {/* <p style={{ color: 'gray'}}>Pavilion <br/>Capital</p> */}
              </div>
              <div style={{ marginRight: '2rem' }}>
                <img
                  style={{
                    borderRadius: '50%',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    border: '0.7vh solid white',
                    borderColor: 'white',
                    height: '17vh',
                    width: '17vh',
                  }}
                  src={CocoI}
                  alt="Team"
                />
                <p style={{ color: 'black' }}>Coco Izutsu</p>
                <hr style={{width:'20%'}}></hr>
                <p style={{ color: 'gray', lineHeight:'3vh'}}>Business Development<br/>  Director</p>
                {/* <hr style={{width:'20%'}}></hr> */}
                {/* <p style={{ color: 'gray'}}>Cus</p> */}
              </div>
              <div style={{ marginRight: '2rem' }}>
                <img
                  style={{
                    borderRadius: '50%',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    border: '0.7vh solid white',
                    borderColor: 'white',
                    height: '17vh',
                    width: '17vh',
                  }}
                  src={GiangN}
                  alt="Team"
                />
                <p style={{ color: 'black' }}>Giang Nguyen</p>
                <hr style={{width:'20%'}}></hr>
                <p style={{ color: 'gray', lineHeight:'3vh'}}>Partnerships <br/> Director</p>
                {/* <hr style={{width:'20%'}}></hr> */}
                {/* <p style={{ color: 'gray', lineHeight:'3vh'}}>MakerDAO <br/> Ambassador</p> */}
         
              </div>
            </div>
           
            <h1
              style={{
                backgroundColor: '',
                fontSize: '10vh',
                color: 'black',
                textAlign: 'left',
                width: '40%',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                marginTop:'2vh'
              }}
            >
              our executives team
              <span className="dot-container2" style={{ marginBottom: '2rem', marginLeft: '2rem' }}></span>
            </h1>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
