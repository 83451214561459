import React, { useState, useEffect } from 'react';
import './Portfolio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faVideo} from '@fortawesome/free-solid-svg-icons';
import CitaDAO1 from '../Assets/CitaDaoA1.png';
import CDLogo from '../Assets/LogoCDao.png';
import MDLogo from '../Assets/LogoMDao.png';
import HLogo from '../Assets/LogoHOne.png';
import DBLogo from '../Assets/LogoDB.png'
import TLogo from '../Assets/LogoTeeC.png';
import CWLogo from '../Assets/LogoCW.png'
import SLogo from '../Assets/LogoS.png'
import DHLogo from '../Assets/LogoDH.png'
import SteemLogo from '../Assets/LogoSteem.png'
import PCLogo from '../Assets/LogoPC.png'
import WCLogo from '../Assets/LogoWC.png'
import WaykiLogo from '../Assets/LogoWayki.png'
import GFLogo from '../Assets/LogoGF.png'
import Navbar from '../Components/Navbar';
import MWLogo from '../Assets/LogoMW.png'
// import Slider from 'react-slick';
import ImageSlider from '../Components/Carousel';


const PortfolioContent = [
  {
    PortfolioNumber: 1,
    CompanyName: 'Cita\nDAO',
    logo: CDLogo,
    CompanyType:'Real World Assets',
    AboutTitle: 'About CitaDAO',
    About: "CitaDAO is a DeFi platform on Ethereum, tokenizing real estate to address liquidity, access, and composability issues. It enables diversification, sustainable yield, and constant liquidity through Real Estate Token and interoperability with other DeFi applications.",
    WhatWeDid: "Provided Marketing advisory services.\n Led content strategy and social media efforts.\n Helped with community management and development.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['8.1 million USD raised.', '6,000 UHNW followers.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 2,
    CompanyName: 'Harmony\nONE',
    logo: HLogo,
    CompanyType:'Layer 1 Blockchain',
    AboutTitle: 'About HarmonyONE',
    About: "Harmony is a blockchain platform designed to facilitate the creation and use of decentralised applications (DApps). The network aims to innovate how decentralised applications work by focusing on random state sharding, which allows the creation of blocks in seconds. Harmony is an open and fast blockchain: its mainnet runs Ethereum applications with 2-second transaction finality and lower fees.",
    WhatWeDid: "Spearheaded branding and marketing campaigns. \n Led pre/post IEO marketing & community management.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['Originated over 510 new investors post IEO.', 'Reach rate of over 90% of the entire addressable market in Vietnam.', 'Generated over 7,000 organic twitter shares & 2,000+ participants for a live event.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 3,
    CompanyName: `Maker\nDAO`,
    logo: MDLogo,
    CompanyType:'Stablecoin',
    AboutTitle: 'About MakerDAO',
    About: "MakerDAO is a pioneering platform for stablecoin cryptocurrency on Ethereum. It operates the Maker Protocol, enabling users to generate Dai by collateralizing approved assets. Maker Governance, a community-driven process, manages the protocol. Dai is a decentralized and unbiased cryptocurrency pegged to the US Dollar, providing economic freedom and stability.",
    WhatWeDid: "We organised and managed 4 key marketing events. \n  We provided end-to-end service of the global OTC network from network development to product launch.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['Generated over $2M in Daily OTC Volume for DAI/VND.','Onboarded 2 major projects on the MakerDAO network.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 4,
    CompanyName: 'Space\nFi',
    logo :SLogo,
    CompanyType:'NFT & DeFi Ecosystem',
    AboutTitle: 'About SpaceFi',
    WhatWeDid: 'Provided social media services and community management services.',
    About: "SpaceFi is a cross-chain web3 platform on Evmos and zkSync, with DEX+NFT+Starter+Spacebase as initial product. Its ultimate vision is to connect Cosmos and Ethereum Layer2 ecosystem, exploring assets cross-chain and interoperability solutions.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['300+ new users for beta test in the first week.', 'Originated 4 new partnerships from strong DeFi projects in 2 weeks.', '20,000 Twitter Impressions peak organic reach.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 5,
    CompanyName: 'Cobo\nWallet',
    logo :CWLogo,
    CompanyType:'Crypto Wallet',
    AboutTitle: 'About Cobo Wallet',
    WhatWeDid: 'Provided social media services and community management services. \n Led cross-project marketing campaigns. \n Led go-to-market campaigns within selected regions in APAC as an exclusive agent.',
    About: "Cobo is a trusted global digital asset custody provider serving over 500 institutional clients worldwide. They offer a range of custody solutions, including full custody, co-managed custody, and decentralized self-custody. In addition, Cobo provides Wallet-as-a-Service, SuperLoop, and Argus, along with compliance and licenses in multiple jurisdictions, making them a reliable choice for institutional digital asset storage.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['Exceeded 20,000 new user goal by acquiring 27,471 users within a 4-day campaign.', 'Grew userbase by 123,900 in 3 months with a 35% retention rate.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 6,
    CompanyName: 'DeepBrain\nChain',
    AboutTitle: 'About DeepBrain Chain',
    logo :DBLogo,
    CompanyType:'Computing Network Project',
    WhatWeDid: 'Led branding advisory.\n Assisted with Exchange listings.\nProvided social media and community management services.',
    About: 'DeepBrain Chain is a leading company that has developed a scalable high-performance computing network. With their blockchain and GPU computing mainnet, they have successfully implemented projects in various industries. Their user-friendly network has gained global adoption, serving companies and AI developers worldwide.',
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['500,000+ reach in SEA.', 'Successful exchange listing.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 7,
    CompanyName: 'Wayki\nChain',
    logo :WaykiLogo,
    CompanyType:'DeFi Gaming Project',
    WhatWeDid: 'Provided media/branding campaign advisroy & ambassador training.',
    AboutTitle: 'About Wakyi Chain',
    About: "WaykiChain is a blockchain platform enabling decentralized applications and smart contracts. With a hybrid consensus mechanism, built-in DEX, cross-chain interoperability, and NFT support, it empowers developers and drives blockchain adoption.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['100+ new active traders on WICC/BTC & WICC/USDT Pairs.', '100,000+ organic reach.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 8,
    CompanyName: 'DHealth\nNetwork',
    logo :DHLogo,
    CompanyType:  'NEM-based health Swiss Blockchain',
    WhatWeDid: 'Conducted media/branding advisory.\n Helped connect them with local Vietnamese health officials & hospitals.',
    AboutTitle: 'About DHealth Network',
    About: "DHealth Network is a blockchain platform revolutionizing healthcare by providing secure and efficient solutions for medical data management. It enables seamless sharing and exchange of health information, empowering individuals and enhancing healthcare outcomes through decentralized and collaborative approaches.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['Originated 100+ major investors.', 'Originated MOU with the Vietnamese government & multiple SEA hospitals.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 9,
    CompanyName: 'Math\nWallet',
    logo :MWLogo,
    CompanyType: 'Crypto Wallet & Token',
    WhatWeDid: 'Conducted branding/media advisory.\n Provided social media marketing and community development and management services.',
    AboutTitle: 'About Math Wallet',
    About: "MathWallet is a powerful universal crypto wallet which support multi-chain tokens and dApps on both mobile / browser extension / web / hardware wallet, backed by Fenbushi Capital.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['6900 new active members within 1 month'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 10,
    CompanyName: 'Walton\nChain',
    logo :WCLogo,
    
    CompanyType: 'IoT Protocol & Subchains',
    WhatWeDid: 'Conducted media/branding advisory.\n Pre/post-ICO marketing\n Provided community development and management services',
    AboutTitle: 'About Walton Chain',
    About: "Waltonchain is a blockchain and IoT platform that improves supply chain management and traceability. By combining RFID technology with blockchain, it enables real-time data collection, verification, and automation. Waltonchain enhances transparency, uces counterfeiting risks, and streamlines transactions within the supply chain.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['Led to a successful ICO round.', 'Originated 1,000+ new investors & traders.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 11,
    CompanyName: 'Tee\nCoin',
    logo :TLogo,
    CompanyType: 'Singaporean Customer Loyalty Program on-chain',
    WhatWeDid: 'Conducted branding advisory\n Helped connect them for listing on a top tier exchange',
    AboutTitle: 'About Tee Coin',
    About: "TEE-Coin is an exchangeable currency to many cryptocurrencies and solves the inability to use cryptocurrencies at merchants. With low service fees and fast processing time, we bring you the solution to top-up TEE-coin with other cryptocurrencies and performing payments quickly and smoothly.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['500,000+ reach in SEA', 'Successful exchange listing'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 12,
    CompanyName: 'Steem\nit',
    logo :SteemLogo,
    CompanyType: 'IoT Protocol & Subchains',
    WhatWeDid: 'Provided social media marketing services and community development and management services.',
    AboutTitle: 'About Steemit',
    About: "Steemit is a blockchain-based blogging and social media website. Users can gain a cryptocurrency, STEEM, for publishing and curating content.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['1M+ reach and 10,000+ impressions on Twitter.', 'Endorsed by Justin Sun on Twitter.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 13,
    CompanyName: 'Glosfer',
    CompanyType: 'Korean 1st-gen Blockchain Payment Gateway',
    logo :GFLogo,
    WhatWeDid: 'Provided social media, community development and management services on Telegram and Wechat.',
    AboutTitle: 'About Glosfer',
    About: "GLOSFER is a prominent South Korean blockchain company expanding its presence in different industries. It offers innovative technologies and services, including its blockchain platform PACKUTH and cryptocurrency trading platforms. Through its Infinity Project and HYCON coin, GLOSFER aims to create a transparent society and collaborate with public institutions.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['Attracted 20,000+ new active members'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  },
  {
    PortfolioNumber: 14,
    CompanyName: 'Pledge\nCamp',
    CompanyType: 'Blockchain Crowdfund Platform',
    logo :PCLogo,
    WhatWeDid: 'Conducted branding/media advisory in SEA.',
    AboutTitle: 'About PledgeCamp',
    About: "Pledgecamp is a nect generation blockchain crowdfunding platform - protecting backers by emphasising accountability and supporting creators, backed by Ripple top executives and Randi Zuckerberg.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['generated 1,000+ new platform users in a single campaign.'],
    slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  }
];



// const sliderSettings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
// };

const containerStyles = {
    width: '100%',
    height:'100%',




    position: 'relative', // Add position relative to enable positioning of the image slider
    backgroundColor:'',
 

  
};

const Services = () => {
  const [portfolioIndex, setPortfolioIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const handleNextPortfolio = () => {
    setIsLoading(true); // Set loading state to true
    setTimeout(() => {
      setPortfolioIndex((prevIndex) => (prevIndex + 1) % PortfolioContent.length);
      setIsLoading(false); // Set loading state to false after a delay
    }, 500); // Adjust the duration as desired
  };

  // const handlePreviousPortfolio = () => {
  //   setIsLoading(true); // Set loading state to true
  //   setTimeout(() => {
  //     setPortfolioIndex((prevIndex) => (prevIndex - 1 + PortfolioContent.length) % PortfolioContent.length);
  //     setIsLoading(false); // Set loading state to false after a delay
  //   }, 1000); // Adjust the duration as desired
  // };

  const [svcnum, setsvcnum] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [isClicked2, setIsClicked2] = useState(false);
  const [isClicked3, setIsClicked3] = useState(false);
  const [isClicked4, setIsClicked4] = useState(false);


  const handleClick = () => {
    setIsClicked(!isClicked);
    setIsClicked2(false);
    setIsClicked3(false);
    setIsClicked4(false);
    setsvcnum(1)
  };

  const handleClick2 = () => {
    setIsClicked2(!isClicked2);
    setIsClicked3(false);
    setIsClicked4(false);
    setIsClicked(false);
    setsvcnum(2)
  };

  const handleClick3 = () => {
    setIsClicked3(!isClicked3);
    setIsClicked4(false);
    setIsClicked(false);
    setIsClicked2(false);
    setsvcnum(3)
  };

  const handleClick4 = () => {
    setIsClicked4(!isClicked4);
    setIsClicked(false);
    setIsClicked2(false);
    setIsClicked3(false);
    setsvcnum(4)
  };


  const boxStyle = {
    backgroundColor: isClicked ? 'black' : '',
    width: isClicked ? 'calc(30vh - 4px)' : '30vh',
  height: isClicked ? 'calc(30vh - 4px)' : '30vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: isClicked? 'auto': 'auto' ,
    textAlign: 'center',
    border: isClicked ? '2px solid white' : 'none',
  };
  const textStyle = {
    color: isClicked ? 'white' : 'white',
    margin: '0',
    paddingTop: '0px',
    textAlign: 'left',
    fontSize:'2.5vh',
    
  };

  const boxStyle2 = {
    backgroundColor: isClicked2 ? 'black' : '',
    width: isClicked2 ? 'calc(30vh - 4px)' : '30vh',
  height: isClicked2 ? 'calc(30vh - 4px)' : '30vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: isClicked2? 'auto': 'auto' ,
    textAlign: 'center',
    border: isClicked2? '2px solid white' : 'none',
  };
  const textStyle2 = {
    color: isClicked2 ? 'white' : 'white',
    margin: '0',
    paddingTop: '0px',
    textAlign: 'left',
    fontSize:'2.5vh',

  };

  const boxStyle3 = {
    backgroundColor: isClicked3 ? 'black' : '',
    width: isClicked3 ? 'calc(30vh - 4px)' : '30vh',
  height: isClicked3 ? 'calc(30vh - 4px)' : '30vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: isClicked3? 'auto': 'auto' ,
    textAlign: 'center',
    border: isClicked3 ? '2px solid white' : 'none',
  };
  const textStyle3 = {
    color: isClicked3 ? 'white' : 'white',
    margin: '0',
    paddingTop: '0px',
    textAlign: 'left',
    fontSize:'2.5vh' 
  };

  const boxStyle4 = {
    backgroundColor: isClicked4 ? 'black' : '',
    width: isClicked4 ? 'calc(30vh - 4px)' : '30vh',
  height: isClicked4 ? 'calc(30vh - 4px)' : '30vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: isClicked4? 'auto': 'auto' ,
    textAlign: 'center',
    border: isClicked4 ? '2px solid white' : 'none',
  };
  const textStyle4 = {
    color: isClicked4 ? 'white' : 'white',
    margin: '0',
    paddingTop: '0px',
    textAlign: 'left',
    fontSize:'2.5vh' 
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500); // Adjust the duration as desired
  }, []);

  
//   #1D2128
  return (


    // <hr style={{width:'100%'}}></hr>
    
    <div style={{backgroundColor:'#FFF8F1', height:'100vh', width:'100%'}}>
           <Navbar whiteLogo={false} onArrowClick={handleNextPortfolio} />
      <div classname='main-div' style={{ filter: isLoading ? 'blur(8px)' : 'none',
      transition: 'filter 1s',backgroundColor: '#1D2128',marginTop:'', borderTopLeftRadius: '150px',height: `calc(100vh - 200px)`,borderBottomRightRadius: '150px',display: 'flex', flexDirection: 'column',  marginRight:'2%'}}>
 <div>
    {svcnum === 0 && (
      <div style={{height:'35vh'}}>
        <h1 style={{ color: 'white', marginLeft: '10%', marginBottom: '2vh' }}>Services.</h1>
        <hr style={{ width: '85%', color: '#BCC1CA', marginLeft: '8%', marginTop: '0px' }} />
        <p style={{ justifyContent: 'left', textAlign: 'left', marginLeft: '10%' }}>A personal holistic approach is our method for our clients; We employ 4 core areas in order to  so no 2 projects are the same.</p>
      </div>
    )}

    {svcnum === 1 && (
    <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', gap: '50vh' , height:'45vh', backgroundColor:'' , maxWidth:'300vh'}}>
        <div style={{ marginTop:'5vh', width:'40vh', backgroundColor:''}}><h1 style={{textAlign:'left', color:'white', fontSize:'8vh', marginLeft:'30%', backgroundColor:''}}>Content<br/> Creation.</h1>  </div>
        <div style={{ backgroundColor: '', display: 'flex', alignItems: '',flexDirection:'column', marginTop:'10vh', width:'140vh', marginRight:'20vh'}}> 
  <h2 style={{ textAlign: 'left', color:'white' }}>Steps we take to create & promote your content</h2>
  <p style={{ textAlign: 'left' }}><strong style={{color:'white'}}>Consult & Research</strong>: We work with clients to understand their goals, audience and unique selling points; We create an aligned content strategy to fit their needs.<br/>

<strong style={{color:'white'}}>Creation</strong>: Develop compelling, tailored content with industry expertise.<br/>
<strong style={{color:'white'}}>Review & Refine</strong>: Gather client feedback to ensure content meets standards.<br/>
<strong style={{color:'white'}}> Delivery</strong>: Final content engages audience, achieves business goals. <br/>
</p>

</div>
  </div>
    )}
    {svcnum === 2 && (
      <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', gap: '50vh' , height:'45vh', backgroundColor:'', maxWidth:'300vh' }}>
      <div style={{ marginTop:'5vh',width:'40vh', backgroundColor:''}}><h1 style={{textAlign:'left', color:'white', fontSize:'8vh', marginLeft:'30%', backgroundColor:''}}>Content<br/> Promotion. </h1>  </div>
      <div style={{  display: 'flex', alignItems: '',flexDirection:'column', marginTop:'10vh', backgroundColor:'', width:'140vh', marginRight:'20vh'}}> 
<h2 style={{ textAlign: 'left', color:'white' }}>What steps do we take to manage your brand</h2>

<p style={{ textAlign: 'left' }}>

    <strong style={{color:'white'}}>Research</strong>: We propose partnerships to help you further establish brand presence. <br/>
<strong style={{color:'white'}}>Gather</strong>: We bring together resources - potential partnerships between projects and influencers.<br/>
<strong style={{color:'white'}}>Delivery</strong>: Engage our resources to grow your brand strategically.<br/>
<strong style={{color:'white'}}>Monitor</strong>: Keep track of our performance with periodic quantitative and qualitative analysis - which will be included during client reports.<br/>
</p>
</div>
</div>
    )}
    {svcnum === 3 && (
      <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', gap: '50vh' , height:'45vh', backgroundColor:'' , maxWidth:'300vh'}}>
      <div style={{ marginTop:'5vh',width:'40vh', backgroundColor:''}}><h1 style={{textAlign:'left', color:'white', fontSize:'8vh', marginLeft:'30%', backgroundColor:''}}>Community<br/> Development. </h1>  </div>
      <div style={{ backgroundColor: '', display: 'flex', alignItems: '',flexDirection:'column', marginTop:'10vh', marginRight:'20vh'}}> 
<h2 style={{ textAlign: 'left', color:'white' }}>How do we develop a community around your brand</h2>
<p style={{ textAlign: 'left' }}>

    <strong style={{color:'white'}}>Outreach</strong>: We use our channels to draw in people to engage with your brand. <br/>
<strong style={{color:'white'}}>Empathise</strong>: Maintain presence and responsiveness to build trust between you and your growing community.<br/>
<strong style={{color:'white'}}>Monitor</strong>: Keep track of our performance with periodic quantitative and qualitative analysis - which will be included during client reports. <br/></p>
</div>
</div>
    )}
    {svcnum === 4 && (
      <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', gap: '50vh' , height:'45vh', backgroundColor:'' , maxWidth:'300vh'}}>
      <div style={{ marginTop:'5vh',width:'40vh', backgroundColor:''}}><h1 style={{textAlign:'left', color:'white', fontSize:'8vh', marginLeft:'30%', backgroundColor:''}}>Community<br/> Management. </h1>  </div>
      <div style={{ backgroundColor: '', display: 'flex', alignItems: '',flexDirection:'column', marginTop:'10vh', marginRight:'20vh'}}> 
<h2 style={{ textAlign: 'left', color:'white' , width:'', backgroundColor:''}}>How do we sustain community engagement</h2>
<p style={{ textAlign: 'left' }}>
    <strong style={{color:'white'}}>Sustain</strong>: We keep the community informed on presently relevant events and project updates.  <br/>
<strong style={{color:'white'}}>Revive</strong>: Conduct periodic events and campaigns to regain traction.<br/>
<strong style={{color:'white'}}>Monitor</strong>: Keep track of our performance with periodic quantitative and qualitative analysis - which will be included during client reports. <br/></p>
</div>
</div>
    )}
  </div>


    <div style={{display:'flex', flexDirection:'row', gap:'', marginLeft:'2vh'}}>
    
    <div style={boxStyle} onClick={handleClick}>
      <h1 style={{ ...textStyle, margin: '0', fontSize:'12vh' }}>01</h1>
      <h2 style={textStyle}>Content<br />Creation </h2>
      <span style={{ backgroundColor: 'white', width: '0.8vh', height: '0.8vh', borderRadius: '50%', display: 'inline-block', marginLeft: '15vh', marginTop:'-1.5vh' }}></span>
     
      
    </div>
    <div style={boxStyle2} onClick={handleClick2}>
      <h1 style={{ ...textStyle2, margin: '0' , fontSize:'12vh' }}>02</h1>
      <h2 style={textStyle2}>Brand<br />Management</h2>
      <span style={{ backgroundColor: 'white', width: '0.8vh', height: '0.8vh', borderRadius: '50%', display: 'inline-block', marginLeft: '17vh', marginTop:'-1.5vh' }}></span>
    </div>
    <div style={boxStyle3} onClick={handleClick3}>
      <h1 style={{ ...textStyle3, margin: '0', fontSize:'12vh'  }}>03</h1>
      <h2 style={textStyle3}>Community<br />Development</h2>
      <span style={{ backgroundColor: 'white', width: '0.8vh', height: '0.8vh', borderRadius: '50%', display: 'inline-block', marginLeft: '19vh', marginTop:'-1.5vh' }}></span>
    </div>
    <div style={boxStyle4} onClick={handleClick4}>
      <h1 style={{ ...textStyle4, margin: '0', fontSize:'12vh'  }}>04</h1>
      <h2 style={textStyle4}>Community<br />Management</h2>
      <span style={{ backgroundColor: 'white', width: '0.8vh', height: '0.8vh', borderRadius: '50%', display: 'inline-block', marginLeft: '17vh', marginTop:'-1.5vh' }}></span>
    </div>
    </div>
       </div>
      
</div>
  );
}

export default Services;
