import React, { useState } from 'react';
import './ContactUs.css';
import { FontAwesomeIcon ,} from '@fortawesome/react-fontawesome';
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../Components/Navbar';
import emailjs from '@emailjs/browser';

const ContacUs = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Simulating a delay to showcase the loading state
  setTimeout(() => {
    setIsLoading(false);
  }, 0);

  return (
    <div style={{ height:`calc(100vh + 200px)`, backgroundColor: '#FFF8F1', marginTop:'', width:'100%'}}>
      <Navbar />
      <div
        style={{
          height: `calc(100vh - 200px)`,
          backgroundColor:'',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          filter: isLoading ? 'blur(5px)' : 'none', // Apply blur effect if isLoading is true
          transition: 'filter 0.5s ease', // Add transition effect
          justifyContent:'center'
        }}
      >


<div style={{ display: 'flex', flexDirection: 'column' }}>
<h1 style={{ backgroundColor: '', fontSize: '8vw', marginBottom: 'rem', color: 'black', textAlign: 'left' }}>
Get in touch<span className="dot-container2"></span>
          </h1>
        {/* <h1 style={{ fontSize: '8rem', display: 'flex', alignItems: 'center', color: 'black', textAlign: 'left' }}>
          Get in touch<span className="dot-container"></span>
        </h1> */}

<div className="email-input-container" style={{ textAlign: 'left' }}>
      <input style={{fontSize:'1.5vw'}} type="text" placeholder="Enter your email and we'll get back to you" />
      <FontAwesomeIcon icon={faEnvelope} className="email-icon" />
      <FontAwesomeIcon icon={faPaperPlane} className="send-icon" onClick={()=>{console.log('sent')}} />
   

    </div>
    
        </div>
      </div>
    </div>
  );
};

export default ContacUs;
