import { useState, useEffect, useRef } from "react";
import { faChevronCircleLeft, faChevronCircleRight, faCircleArrowRight, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const slideStyles = {
  width: "100%",
  height: "100%",

  backgroundSize: "cover",
  backgroundPosition: "center",
  transition: "opacity 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out",
  backdropFilter: "blur(20px)",
  boxShadow: '0 0 40px rgba(255, 255, 255, 0.4)',
};

const rightArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  right: "32px",
  fontSize: "3vw",
  color: "#1D2128",
  zIndex: 99,
  cursor: "pointer",
  fontWeight: 100,
  
};

const leftArrowStyles = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  left: "32px",
  fontSize: "3vw",
  color: "#1D2128",
  zIndex: 99,
  cursor: "pointer",
  fontWeight: "thin",

};

const sliderStyles = {
  position: "relative",
  height: "100%",
};

const dotsContainerStyles = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  bottom: "0",
  left: "50%",
  transform: "translateX(-50%)",
  marginBottom: "1vw",
};

const dotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
};

const activeDotStyle = {
  margin: "0 3px",
  cursor: "pointer",
  fontSize: "20px",
  color: "white",
};

const ImageSlider = ({ slides, activeSlide, setActiveSlide }) => {
  const [opacity, setOpacity] = useState(0);
  const sliderRef = useRef(null);

  const goToPrevious = () => {
    const isFirstSlide = activeSlide === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : activeSlide - 1;
    setOpacity(0.7);
    setTimeout(() => {
      setActiveSlide(newIndex);
      setOpacity(1);
    }, 250);
  };

  const goToNext = () => {
    const isLastSlide = activeSlide === slides.length - 1;
    const newIndex = isLastSlide ? 0 : activeSlide + 1;
    setOpacity(0.7);
    setTimeout(() => {
      setActiveSlide(newIndex);
      setOpacity(1);
    }, 250);
  };

  const goToSlide = (slideIndex) => {
    setActiveSlide(slideIndex);
  };

  useEffect(() => {
    setOpacity(0.7);
    setTimeout(() => {
      setOpacity(1);
    }, 250);
  }, [activeSlide]);

  const slideStylesWidthBackground = {
    ...slideStyles,
    backgroundImage: `url(${slides[activeSlide].url})`,
    opacity: opacity,
  };

  return (
    <div style={sliderStyles} ref={sliderRef}>
      <div>
        <div onClick={(e) => {
          e.stopPropagation();
          goToPrevious();
        }} style={leftArrowStyles}>
          <FontAwesomeIcon icon={faCircleArrowLeft} color='' />
        </div>
        <div onClick={(e) => {
          e.stopPropagation();
          goToNext();
        }} style={rightArrowStyles}>
          <FontAwesomeIcon icon={faCircleArrowRight} />
        </div>
      </div>
      <div style={slideStylesWidthBackground}></div>
      <div style={dotsContainerStyles}>
        {slides.map((slide, slideIndex) => (
          <div
            style={activeSlide === slideIndex ? activeDotStyle : dotStyle}
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
          >
            ●
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
