import React, { useEffect } from 'react';
import logo from "../Assets/EnvisionMedia.png";
import logoW from "../Assets/EnvisionMediaW.png";
import "./Navbar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ whiteLogo, onArrowClick }) => {
  useEffect(() => {
    const navbarItems = document.querySelectorAll('.navbar-item');
    navbarItems.forEach((item) => item.classList.add('show-underline'));
  }, []);

  const handleArrowClick = () => {
    if (onArrowClick) {
      onArrowClick();
    }
  };

  return (
    <nav style={{position:'sticky', top:'0px', backgroundColor: whiteLogo? '': '#FFF8F1', zIndex:'3'}}>
      <ul className='navbar-list'>
      <div className="navbar-items-container">
        <a href="/" style={{}} >
          <img src={whiteLogo ? logoW : logo} style={{ height: '150px', display: 'flex', alignItems: 'center' }} alt="Logo" />
        </a>
       
        {!whiteLogo? (
          <>
            {window.location.pathname !== '/aboutus' && (
  <a className='navbar-item' href='/aboutus'>About Us</a>
)}

{window.location.pathname !== '/eventcalendar' && (
  <a className='navbar-item' href='/eventcalendar'>Event Calendar</a>
)}
            
          
            {window.location.pathname !== '/contactus' && (
  <a className='navbar-item' href='/contactus'>Contact Us</a>
)}

            {window.location.pathname !== '/sevices' && (
  <a className='navbar-item' href='/services'>Services</a>
)}


{window.location.pathname !== '/portfolio' && (
  <a className='navbar-item' href='/portfolio'>Portfolio</a>
)}

          </>
        ): (
            <>
            <a className='navbar-item white-font' href="/aboutus">About Us</a>
              <a className='navbar-item white-font' href="/eventcalendar">Event Calendar</a>
              <a className='navbar-item white-font' href={window.location.pathname !== '/' ? '/contactus' : '#'}>Contact Us</a>
              <a className='navbar-item white-font'  href="/services">Services</a>
              {window.location.pathname !== '/portfolio' && (
  <a className='navbar-item' href='/portfolio'>Portfolio</a>
)}

            </>
          )}
          <ul className="navbar-menu-icon" onClick={handleArrowClick}>
          <FontAwesomeIcon style={{ fontSize: '3vw', color: whiteLogo ? '#BCC1CA' : 'black', padding:'0vw'}}  icon={!whiteLogo? ('') : (faChevronCircleRight)} />
        </ul>
</div>
        
      </ul>
    </nav>
  );
};

export default Navbar;
