import React, { useState } from 'react';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../Components/Navbar';

const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  // Simulating a delay to showcase the loading state
  setTimeout(() => {
    setIsLoading(false);
  }, 0);

  return (
    <div
      style={{
        minHeight: `calc(100vh)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        width:'100%',

        backgroundColor: '#FFF8F1',
     
      }}
    >
      <Navbar />
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
    
          filter: isLoading ? 'blur(5px)' : 'none', // Apply blur effect if isLoading is true
          transition: 'filter 0.5s ease', // Add transition effect
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <h1 style={{ backgroundColor: '', fontSize: '20vh', marginBottom: '2rem', color: 'black', textAlign: 'left' }}>
            Envision<span className="dot-container-2"></span>
          </h1>
          <p style={{ backgroundColor: '', fontSize: '2rem', marginTop: '0', color: 'black', textAlign: 'left' }}>
            a professional touch on your marketing content.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
