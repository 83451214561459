import React, { useState, useEffect, useRef } from 'react';
import './Portfolio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faVideo} from '@fortawesome/free-solid-svg-icons';
import CitaDAO1 from '../Assets/CitaDaoA1.png';
import CDLogo from '../Assets/LogoCDao.png';
import MDLogo from '../Assets/LogoMDao.png';
import HLogo from '../Assets/LogoHOne.png';
import DBLogo from '../Assets/LogoDB.png'
import TLogo from '../Assets/LogoTeeC.png';
import CWLogo from '../Assets/LogoCW.png'
import SLogo from '../Assets/LogoS.png'
import DHLogo from '../Assets/LogoDH.png'
import SteemLogo from '../Assets/LogoSteem.png'
import PCLogo from '../Assets/LogoPC.png'
import WCLogo from '../Assets/LogoWC.png'
import WaykiLogo from '../Assets/LogoWayki.png'
import GFLogo from '../Assets/LogoGF.png'
import Navbar from '../Components/Navbar';
import MWLogo from '../Assets/LogoMW.png'
// import Slider from 'react-slick';

import CitaDaoP1 from '../Assets/CitaDaoAssets/CitaDaoP1.png'
import CitaDaoP2 from '../Assets/CitaDaoAssets/CitaDaoP2.png'
import CitaDaoP3 from '../Assets/CitaDaoAssets/CitaDaoP3.png'


import HP1 from '../Assets/HarmonyOneAssets/HP1.png'

import MkrP1 from '../Assets/MakerDAOAssets/MkrP1.png'
import MkrP2 from '../Assets/MakerDAOAssets/MkrP2.png'
import MkrP3 from '../Assets/MakerDAOAssets/MkrP3.png'

import SFP1 from '../Assets/SpaceFiAssets/SFP1.png'
import SFP2 from '../Assets/SpaceFiAssets/SFP2.png'

import CWP1 from '../Assets/CoboWAssets/CoboP1.png'

import YouTube from 'react-youtube';
import ImageSlider from '../Components/Carousel';


const PortfolioContent = [
  {
    PortfolioNumber: 1,
    CompanyName: 'Cita\nDAO',
    logo: CDLogo,
    CompanyType:'Real World Assets',
    AboutTitle: 'About CitaDAO',
    About: "CitaDAO is a DeFi platform on Ethereum, tokenizing real estate to address liquidity, access, and composability issues. It enables diversification, sustainable yield, and constant liquidity through Real Estate Token and interoperability with other DeFi applications.",
    WhatWeDid: "Provided Marketing advisory services.\n Led content strategy and social media efforts.\n Helped with community management and development.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['8.1 million USD raised.', '6,000 UHNW followers.'],
    slides: [{ url: CitaDaoP1, title: 'wolf1' }, { url: CitaDaoP2, title: 'wolf2' }, { url: CitaDaoP3, title: 'wolf2' }],
    VideoID: 'cZXZKLECzDQ',
  },
  {
    PortfolioNumber: 2,
    CompanyName: 'Harmony\nONE',
    logo: HLogo,
    CompanyType:'Layer 1 Blockchain',
    AboutTitle: 'About HarmonyONE',
    About: "Harmony is a blockchain platform designed to facilitate the creation and use of decentralised applications (DApps). The network aims to innovate how decentralised applications work by focusing on random state sharding, which allows the creation of blocks in seconds. Harmony is an open and fast blockchain: its mainnet runs Ethereum applications with 2-second transaction finality and lower fees.",
    WhatWeDid: "Spearheaded branding and marketing campaigns. \n Led pre/post IEO marketing & community management.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['Originated over 510 new investors post IEO.', 'Reach rate of over 90% of the entire addressable market in Vietnam.', 'Generated over 7,000 organic twitter shares & 2,000+ participants for a live event.'],
    slides: [{ url: HP1, title: 'wolf1' }],
    VideoID: 'NgNnp3rKUDc',
  },
  {
    PortfolioNumber: 3,
    CompanyName: `Maker\nDAO`,
    logo: MDLogo,
    CompanyType:'Stablecoin',
    AboutTitle: 'About MakerDAO',
    About: "MakerDAO is a pioneering platform for stablecoin cryptocurrency on Ethereum. It operates the Maker Protocol, enabling users to generate Dai by collateralizing approved assets. Maker Governance, a community-driven process, manages the protocol. Dai is a decentralized and unbiased cryptocurrency pegged to the US Dollar, providing economic freedom and stability.",
    WhatWeDid: "We organised and managed 4 key marketing events. \n  We provided end-to-end service of the global OTC network from network development to product launch.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['Generated over $2M in Daily OTC Volume for DAI/VND.','Onboarded 2 major projects on the MakerDAO network.'],
    slides: [{ url: MkrP1, title: 'wolf1' }, { url: MkrP2, title: 'wolf2' }, { url: MkrP3, title: 'wolf2' }],
    VideoID: 'Di5xUzvyEPM',

  },
  {
    PortfolioNumber: 4,
    CompanyName: 'Space\nFi',
    logo :SLogo,
    CompanyType:'NFT & DeFi Ecosystem',
    AboutTitle: 'About SpaceFi',
    WhatWeDid: 'Provided social media services and community management services.',
    About: "SpaceFi is a cross-chain web3 platform on Evmos and zkSync, with DEX+NFT+Starter+Spacebase as initial product. Its ultimate vision is to connect Cosmos and Ethereum Layer2 ecosystem, exploring assets cross-chain and interoperability solutions.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['300+ new users for beta test in the first week.', 'Originated 4 new partnerships from strong DeFi projects in 2 weeks.', '20,000 Twitter Impressions peak organic reach.'],
    slides: [{ url: SFP1, title: 'wolf1' }, { url: SFP2, title: 'wolf2' },],
    VideoID: 'R3agxX4zTpw',  
  },
  {
    PortfolioNumber: 5,
    CompanyName: 'Cobo\nWallet',
    logo :CWLogo,
    CompanyType:'Crypto Wallet',
    AboutTitle: 'About Cobo Wallet',
    WhatWeDid: 'Provided social media services and community management services. \n Led cross-project marketing campaigns. \n Led go-to-market campaigns within selected regions in APAC as an exclusive agent.',
    About: "Cobo is a trusted global digital asset custody provider serving over 500 institutional clients worldwide. They offer a range of custody solutions, including full custody, co-managed custody, and decentralized self-custody. In addition, Cobo provides Wallet-as-a-Service, SuperLoop, and Argus, along with compliance and licenses in multiple jurisdictions, making them a reliable choice for institutional digital asset storage.",
    ProjectDuration: "November 10th - July 4th 2022",
    NotableAchiements: ['Exceeded 20,000 new user goal by acquiring 27,471 users within a 4-day campaign.', 'Grew userbase by 123,900 in 3 months with a 35% retention rate.'],
    slides: [{ url: CWP1, title: 'wolf1' }],
    VideoID: 'NzvHPHI-z4g',  
  },
  // {
  //   PortfolioNumber: 6,
  //   CompanyName: 'DeepBrain\nChain',
  //   AboutTitle: 'About DeepBrain Chain',
  //   logo :DBLogo,
  //   CompanyType:'Computing Network Project',
  //   WhatWeDid: 'Led branding advisory.\n Assisted with Exchange listings.\nProvided social media and community management services.',
  //   About: 'DeepBrain Chain is a leading company that has developed a scalable high-performance computing network. With their blockchain and GPU computing mainnet, they have successfully implemented projects in various industries. Their user-friendly network has gained global adoption, serving companies and AI developers worldwide.',
  //   ProjectDuration: "November 10th - July 4th 2022",
  //   NotableAchiements: ['500,000+ reach in SEA.', 'Successful exchange listing.'],
  //   slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  //   VideoID: 'V6k8ggbBkSk',  
  // },
  // {
  //   PortfolioNumber: 7,
  //   CompanyName: 'Wayki\nChain',
  //   logo :WaykiLogo,
  //   CompanyType:'DeFi Gaming Project',
  //   WhatWeDid: 'Provided media/branding campaign advisroy & ambassador training.',
  //   AboutTitle: 'About Wakyi Chain',
  //   About: "WaykiChain is a blockchain platform enabling decentralized applications and smart contracts. With a hybrid consensus mechanism, built-in DEX, cross-chain interoperability, and NFT support, it empowers developers and drives blockchain adoption.",
  //   ProjectDuration: "November 10th - July 4th 2022",
  //   NotableAchiements: ['100+ new active traders on WICC/BTC & WICC/USDT Pairs.', '100,000+ organic reach.'],
  //   slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  //   VideoID: 'h37Yh5N3288',  
  // },
  // {
  //   PortfolioNumber: 8,
  //   CompanyName: 'DHealth\nNetwork',
  //   logo :DHLogo,
  //   CompanyType:  'NEM-based health Swiss Blockchain',
  //   WhatWeDid: 'Conducted media/branding advisory.\n Helped connect them with local Vietnamese health officials & hospitals.',
  //   AboutTitle: 'About DHealth Network',
  //   About: "DHealth Network is a blockchain platform revolutionizing healthcare by providing secure and efficient solutions for medical data management. It enables seamless sharing and exchange of health information, empowering individuals and enhancing healthcare outcomes through decentralized and collaborative approaches.",
  //   ProjectDuration: "November 10th - July 4th 2022",
  //   NotableAchiements: ['Originated 100+ major investors.', 'Originated MOU with the Vietnamese government & multiple SEA hospitals.'],
  //   slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  //   VideoID: 'nKPe_NwPjMw',  
  // },
  // {
  //   PortfolioNumber: 9,
  //   CompanyName: 'Math\nWallet',
  //   logo :MWLogo,
  //   CompanyType: 'Crypto Wallet & Token',
  //   WhatWeDid: 'Conducted branding/media advisory.\n Provided social media marketing and community development and management services.',
  //   AboutTitle: 'About Math Wallet',
  //   About: "MathWallet is a powerful universal crypto wallet which support multi-chain tokens and dApps on both mobile / browser extension / web / hardware wallet, backed by Fenbushi Capital.",
  //   ProjectDuration: "November 10th - July 4th 2022",
  //   NotableAchiements: ['6900 new active members within 1 month'],
  //   slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  //   VideoID: '7O7LCLxXsFA',  
  // },
  // {
  //   PortfolioNumber: 10,
  //   CompanyName: 'Walton\nChain',
  //   logo :WCLogo,
    
  //   CompanyType: 'IoT Protocol & Subchains',
  //   WhatWeDid: 'Conducted media/branding advisory.\n Pre/post-ICO marketing\n Provided community development and management services',
  //   AboutTitle: 'About Walton Chain',
  //   About: "Waltonchain is a blockchain and IoT platform that improves supply chain management and traceability. By combining RFID technology with blockchain, it enables real-time data collection, verification, and automation. Waltonchain enhances transparency, reduces counterfeiting risks, and streamlines transactions within the supply chain.",
  //   ProjectDuration: "November 10th - July 4th 2022",
  //   NotableAchiements: ['Led to a successful ICO round.', 'Originated 1,000+ new investors & traders.'],
  //   slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  //   VideoID: 'mcxSe56jXqY',  
  // },
  // {
  //   PortfolioNumber: 11,
  //   CompanyName: 'Tee\nCoin',
  //   logo :TLogo,
  //   CompanyType: 'Singaporean Customer Loyalty Program on-chain',
  //   WhatWeDid: 'Conducted branding advisory\n Helped connect them for listing on a top tier exchange',
  //   AboutTitle: 'About Tee Coin',
  //   About: "TEE-Coin is an exchangeable currency to many cryptocurrencies and solves the inability to use cryptocurrencies at merchants. With low service fees and fast processing time, we bring you the solution to top-up TEE-coin with other cryptocurrencies and performing payments quickly and smoothly.",
  //   ProjectDuration: "November 10th - July 4th 2022",
  //   NotableAchiements: ['500,000+ reach in SEA', 'Successful exchange listing'],
  //   slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  //   VideoID: 'GVewwKDgoug',  
  // },
  // {
  //   PortfolioNumber: 12,
  //   CompanyName: 'Steem\nit',
  //   logo :SteemLogo,
  //   CompanyType: 'IoT Protocol & Subchains',
  //   WhatWeDid: 'Provided social media marketing services and community development and management services.',
  //   AboutTitle: 'About Steemit',
  //   About: "Steemit is a blockchain-based blogging and social media website. Users can gain a cryptocurrency, STEEM, for publishing and curating content.",
  //   ProjectDuration: "November 10th - July 4th 2022",
  //   NotableAchiements: ['1M+ reach and 10,000+ impressions on Twitter.', 'Endorsed by Justin Sun on Twitter.'],
  //   slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  //   VideoID: 'X1bkESeSntI',  
  // },
  // {
  //   PortfolioNumber: 13,
  //   CompanyName: 'Glosfer',
  //   CompanyType: 'Korean 1st-gen Blockchain Payment Gateway',
  //   logo :GFLogo,
  //   WhatWeDid: 'Provided social media, community development and management services on Telegram and Wechat.',
  //   AboutTitle: 'About Glosfer',
  //   About: "GLOSFER is a prominent South Korean blockchain company expanding its presence in different industries. It offers innovative technologies and services, including its blockchain platform PACKUTH and cryptocurrency trading platforms. Through its Infinity Project and HYCON coin, GLOSFER aims to create a transparent society and collaborate with public institutions.",
  //   ProjectDuration: "November 10th - July 4th 2022",
  //   NotableAchiements: ['Attracted 20,000+ new active members'],
  //   slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  //   VideoID: 'UWe2Ko8tg2Q',  
  // },
  // {
  //   PortfolioNumber: 14,
  //   CompanyName: 'Pledge\nCamp',
  //   CompanyType: 'Blockchain Crowdfund Platform',
  //   logo :PCLogo,
  //   WhatWeDid: 'Conducted branding/media advisory in SEA.',
  //   AboutTitle: 'About PledgeCamp',
  //   About: "Pledgecamp is a nect generation blockchain crowdfunding platform - protecting backers by emphasising accountability and supporting creators, backed by Ripple top executives and Randi Zuckerberg.",
  //   ProjectDuration: "November 10th - July 4th 2022",
  //   NotableAchiements: ['generated 1,000+ new platform users in a single campaign.'],
  //   slides: [{ url: CitaDAO1, title: 'wolf1' }, { url: CitaDAO1, title: 'wolf2' }, { url: CitaDAO1, title: 'wolf2' }],
  //   VideoID: 'DCBABbhNUfY',  
  // }
];



// const sliderSettings = {
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
// };

const containerStyles = {
    width: '100%',
    height:'100%',




    position: 'relative', // Add position relative to enable positioning of the image slider
    backgroundColor:'',
 

  
};


const Portfolio = () => {
  const [portfolioIndex, setPortfolioIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const handleNextPortfolio = () => {
  
    setIsLoading(true); // Set loading state to true
    
    setTimeout(() => {
      setPortfolioIndex((prevIndex) => (prevIndex + 1) % PortfolioContent.length);
      setActiveSlide(0);
    console.log(activeSlide)
      
      setIsLoading(false); // Set loading state to false after a delay
    }, 500); // Adjust the duration as desired
  };

  // const handlePreviousPortfolio = () => {
  //   setIsLoading(true); // Set loading state to true
  //   setTimeout(() => {
  //     setPortfolioIndex((prevIndex) => (prevIndex - 1 + PortfolioContent.length) % PortfolioContent.length);
  //     setIsLoading(false); // Set loading state to false after a delay
  //   }, 1000); // Adjust the duration as desired
  // };

  const handleSlideClick = (index) => {
    setCurrentSlideIndex(index);
    setIsModalOpen(true);
    console.log(currentSlideIndex);
    console.log(isModalOpen)
  
  };

  const currentPortfolio = PortfolioContent[portfolioIndex];
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500); // Adjust the duration as desired
  }, []);
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
//   #1D2128
  return (


    // <hr style={{width:'100%'}}></hr>
      <div classname='main-div' style={{ filter: isLoading ? 'blur(8px)' : 'none',
      transition: 'filter 1s',backgroundColor: '#1D2128',borderTopRightRadius: '150px',height: '100vh',borderBottomLeftRadius: '150px',display: 'flex', flexDirection: 'column',    boxShadow: '0 0 40px rgba(0, 0, 0, 1)', marginRight:'2%'}}>
       
    
        <Navbar whiteLogo={true} onArrowClick={handleNextPortfolio} />


        <div style={{
    height: `calc(100vh - 200px)`, // Set the height of the parent div to 100%
    display: 'flex',
    flexDirection: 'column',
    backgroundColor:'',


  }}>
    <div style={{
      backgroundColor: '', // Just for visualization
      height: '70%',
      display:'flex',

      flexDirection: 'row',
     
    }}>
      <div style={{width:'30%', backgroundColor:'', height:'100%'}}>

      <div style={{ display: 'flex', height:'50%', backgroundColor:'', marginLeft:'12%', alignItems: '', justifyContent:'left'}}>
  <img src={currentPortfolio.logo} style={{ height: '10vw', width:'10vw', borderRadius: '10px', backgroundColor: 'white', boxShadow: '0 0 20px rgba(255, 255, 255, 1)'}} alt="Logo" />
  <div>
    {/* <h1 style={{minHeight:'7vw', fontSize: '3vw', fontWeight: '20', wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'pre-wrap', marginLeft: '5vh', textAlign:'left', marginTop:'-0.5vw'}} >
      {currentPortfolio.CompanyName.replace(' ', '\n')}
    </h1> */}
    
    {/* <div>
    <h2 style={{marginLeft:'0vw', backgroundColor:'#BCC1CA', borderRadius:'25px', color:'black', padding:'0.5vw', maxWidth:'70%', textAlign: 'center' }}>{currentPortfolio.CompanyType}</h2>
  </div> */}
  </div>
</div>

<div style={{marginTop:'9%', backgroundColor:'',height:'40%'}}>
<h2 style={{backgroundColor:'', fontSize: '1vw',   textAlign: 'left', marginLeft: '10%', alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: '',height:'30%', marginBottom:'0px'}}>
  <FontAwesomeIcon style={{ height: '50%', marginRight: '0.5vw',backgroundColor:'' }} icon={faVideo} />
  <span> {currentPortfolio.CompanyName.replace(/\n/g, '')} project video</span>
</h2>




    <YouTube
      opts={{
        width: '80%',
        height: '30%',
        justifyContent:'center',
       
        playerVars: {
          autoplay: 0,
        },
      }}
      videoId={currentPortfolio.VideoID}
    />

   

    </div>



      </div>
      <div style={{width:'70%', backgroundColor:'', marginTop:''}}>
      <div className="content-container1" style={{backgroundColor:'',height:'60%', width:'80%'}}>

{/* About Company, About Description */}
                <div className="content-block" style={{backgroundColor:'', width:'100%'}}>
                  <h1 style={{ fontSize: '2vw', fontWeight: '20' , textAlign:'left', marginTop:'0px', color:'#BCC1CA', fontFamily:'Roboto Mono, monospace'}}>{currentPortfolio.AboutTitle}</h1>
                  <div className="paragraph" style={{textAlign:'left',  backgroundColor:''}}>
                    <p style={{fontSize: '1.2vw',
    lineHeight: '1.8vw', marginTop:'0px'}} >{currentPortfolio.About}</p>
                  </div>
                </div>
{/* What We Did,  Description */}               
<div className="content-block2" style={{ backgroundColor: '' }}>
  <h1 style={{ fontSize: '2vw', fontWeight: '20', textAlign: 'left', marginTop: '0vh' , color:'#BCC1CA', fontFamily:'Roboto Mono, monospace'}}>What We Did</h1>
  <div className="paragraph" style={{ textAlign: 'left', width:'140%' }}>
    {currentPortfolio.WhatWeDid.split('\n').map((paragraph, index) => (
      <p key={index} style={{ fontSize: '1.2vw',
      lineHeight: '1.8vw', marginBottom: '-0.7vw'}}>{paragraph}</p>
    ))}
  </div>
</div>
       
            </div>
            
                      
                        <div style={{ width: '', backgroundColor: '', height: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '-2%' }}>
 
  <div style={containerStyles} >
  <h1 style={{ fontSize: '3.5vw', fontWeight: '20', display: 'flex', marginLeft: '80%', backgroundColor: '', textAlign: '' , color:'#BCC1CA', fontFamily:'Roboto Mono, monospace', marginTop:'0px',marginBottom:'0px'}}>
  {currentPortfolio.PortfolioNumber < 10 ? `0${currentPortfolio.PortfolioNumber}` : currentPortfolio.PortfolioNumber}/0{PortfolioContent.length}
</h1>

      {/* Your other content here */}
     
  
     
  <div style={{ width: '100%', height: '50%', backgroundColor: '',}} onClick={() => handleSlideClick(activeSlide)}>
    <ImageSlider 
    activeSlide={activeSlide}
    setActiveSlide={setActiveSlide}
    slides={currentPortfolio.slides}
    sliderRef={sliderRef}/>
  </div>


  </div>
</div>

      </div>
      
    </div>


    <hr style={{ width: '98%',  color: '#BCC1CA', marginLeft:'2%', marginTop:'0%',marginBottom:'0%'}} /> 

    <div style={{
      backgroundColor: '', // Just for visualization
      height:'60%'
  
    }}>

<div style={{ display: 'flex', marginLeft:'7%', width:'50%', backgroundColor:'',marginTop:'1%'}}>
  <div style={{ background: '', height: '30%', flex: 1, width:'30%', backgroundColor:'' }}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: '' }}>
        <h1 style={{ fontSize: '1.4vw', color:'#BCC1CA', fontFamily:'Roboto Mono, monospace'}}>Project Duration</h1>
        <div className="paragraph" style={{marginTop: '1.7vw'}}>
          <p style={{ textAlign: 'left' , lineHeight:'1.6vw' , marginTop:'-0.7vw' }}>{currentPortfolio.ProjectDuration}</p>
        </div>
      
      </div>
    </div>
  </div>

  <div style={{ backgroundColor: '', height: '10%', flex: 1 }}>

    <div style={{ display: '' }}>
      <h1 style={{ fontSize: '1.4vw', color:'#BCC1CA', fontFamily:'Roboto Mono, monospace'}}>Notable Achievements</h1>
      <div className="paragraph"style={{marginTop: '2vw', width:'200%', backgroundColor:''}}>
            {currentPortfolio.NotableAchiements.map((achievement, index) => (
              <p style={{ textAlign: 'left', lineHeight:'1.6vw', marginTop:'-1vw' }} key={index}>
                {achievement}
              </p>
              
            ))}
          </div>
    </div>
  </div>
 
    </div>
  </div>

  {isModalOpen && (
        <div
          className="modal-open"
          onClick={() => setIsModalOpen(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: isModalOpen ? 1 : 0, // Set initial opacity to 0 and use conditional rendering for fade-in effect
            transition: 'opacity 1=s', // Add transition for opacity change
            zIndex: 99,
          }}
        >

          <img
            src={currentPortfolio.slides[currentSlideIndex].url}
            alt={currentPortfolio.slides[currentSlideIndex].title}
            style={{ maxWidth: '90%', maxHeight: '90%', width:'', height:'90%', borderTopRightRadius:'150px', borderBottomLeftRadius: '150px', zIndex:'999', boxShadow: '0 0 40px rgba(255, 255, 255, 1)' }}
          />
        </div>
      )}

</div>    
  </div>
  );
};

export default Portfolio;
