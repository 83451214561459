import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home.js'
import React, { useEffect, useState } from 'react';
import Portfolio from './Pages/Portfolio2.js'
import ContactUs from './Pages/ContactUs.js'
import Services from './Pages/Services.js'
import EventCalendar from './Pages/EventCalendar.js'
import { BrowserRouter as Router, Routes, Route ,useLocation } from "react-router-dom";
import Navbar from './Components/Navbar';
import landscape from './Assets/landscape.png'

import AboutUs from './Pages/AboutUs';
function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );
    setIsLandscape(Math.abs(window.orientation) === 90);

    const checkOrientation = () => {
      setIsLandscape(Math.abs(window.orientation) === 90);
    };

    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('orientationchange', checkOrientation);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('orientationchange', checkOrientation);
      window.removeEventListener('resize', handleResize);
    };
  }, []);


 
  return (
    <div className="App" style={{ backgroundColor: '', height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Router style={{ margin: '0', padding: '0' }}>
      {isMobile ? (
        <div style={{ backgroundColor: '#1D2128', height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display:'flex', width: '100vh', height: '50vh', marginTop:'60%', backgroundColor:'#FFF8F1', margin:"2vw" , boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' , justifyContent:'center'}}>
         <h1 style={{ fontSize: '4vh', justifyContent: 'center', marginLeft:'0%', marginTop:'0%' }}>Our mobile site is being upgraded - please view contents on a desktop.</h1>
          {/* <img src={landscape} style={{height:'30vh'}} alt="Landscape" />
          <h1 style={{ fontSize: '8vw', justifyContent: 'center', marginLeft:'0%', marginTop:'0%' }}>Please view in<br/>landscape mode</h1> */}
        </div>
        </div>
      ) : (
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/eventcalendar" element={<EventCalendar />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      )}
    </Router>
  </div>
  );
}

export default App;
